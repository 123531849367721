import moment from 'moment';
import _ from 'lodash';
import uniqid from 'uniqid';
import Cookies from 'js-cookie';
import { getContrastColor, readInputFile } from '../utils/utils';

const axios = require('axios');
const { publicRuntimeConfig } = require('next/config').default();
const { salsaURL, sseSource, webAppURL } = require('../constants').get();
const { webAppURL: prodWebAppURL } = require('../constants').get('prod');

class BackendSalsa {
  constructor() {
    this.URL = salsaURL;
  }

  getEvent({ eventId, token }) {
    return this._salsaRestApi(`/events/${eventId}/basic/${token}?level=6`, { method: 'GET' });
  }

  getEventAnalytics({ analyticsToken, eventId }) {
    return this._salsaRestApi(`/events/${eventId}/analytics/${analyticsToken}?level=3`, { method: 'GET' });
  }

  getSurveyResults({ eventId, token, subdomain }) {
    const query = `query{
                surveyResults(
                    id: "${eventId}",
                    analyticsToken: "1234"
                ) {
                    rows {
                        question,
                        answers {
                            value,
                            count
                        }
                    }
                }
            }`;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getMediasWithFilters({ eventId, eventMedias, filters }) {
    return this.getMedias({ eventId, eventMedias, filters });
  }

  async getMedias({ eventId, eventMedias, dateFrom = null, filters }) {
    const dates = _.map(eventMedias, 'date');
    const minDate = dates?.length ? _.min(dates) : dateFrom;
    const maxDate = dates?.length ? _.max(dates) : null;
    const photosBefore = await this._salsaRestApi(`/events/${eventId}/medias?level=6`, {
      method: 'POST',
      body: {
        filters,
        pagination: {
          count: 12,
          before: minDate,
        },
      },
    });
    const photosBeforeBody = await photosBefore.json();
    if (!photosBeforeBody?.data) {
      throw new Error('Error fetching media');
    }
    if (!maxDate) {
      return { data: { photosBefore: photosBeforeBody.data } };
    }
    const photosAfter = await this._salsaRestApi(`/events/${eventId}/medias?level=6`, {
      method: 'POST',
      body: {
        filters,
        pagination: {
          count: 12,
          after: maxDate,
        },
      },
    });
    const photosAfterBody = await photosAfter.json();
    if (!photosAfterBody?.data) {
      throw new Error('Error fetching media');
    }
    return {
      data: {
        photosBefore: photosBeforeBody.data,
        photosAfter: photosAfterBody.data,
      },
    };
  }

  getMediasByIds({ eventId, mediaIds = [], subdomain }) {
    return this._salsaRestApi(`/events/${eventId}/medias`, {
      method: 'POST',
      body: { mediaIds },
    });
  }

  getMedia({ eventId, mediaId }) {
    return this._salsaRestApi(`/events/${eventId}/medias/${mediaId}`, { method: 'GET' });
  }

  getTemplate({ eventId, mediaId, callback }) {
    return null;
  }

  transformEvent({ eventBody }) {
    const { data, errors } = eventBody;
    if (data) {
      const actionEvent = data;
      const eventId = data.eventId;

      // Format date
      const date = moment(actionEvent.eventDate).format('MMMM Do, YYYY');

      // Total number of captures in event
      const totalPhotos = actionEvent.analytics.totalPhotos;

      // Final event object
      const hasFeaturedImageEnabled =
        (actionEvent.fiestaEvent && actionEvent.liveGallery.featuredImageEnabled) ||
        (!actionEvent.fiestaEvent && actionEvent.liveGallery.leadSection);
      const hasSubtitleEnabled =
        (actionEvent.fiestaEvent && actionEvent.liveGallery.subtitleEnabled) ||
        (!actionEvent.fiestaEvent && actionEvent.liveGallery.leadSection);
      const hasLogoEnabled = actionEvent.liveGallery.leadSection;
      let event = {
        eventId,
        id: actionEvent.id,
        date,
        dateMoment: moment(actionEvent.eventDate),
        title: actionEvent.title,
        subtitle: hasSubtitleEnabled && actionEvent.subtitle,
        logo: hasLogoEnabled && actionEvent.logoUrl,
        logoLink: actionEvent.logoLink || '',
        buttonFlag: actionEvent.buttonFlag,
        buttonText: actionEvent.buttonText,
        buttonLink: actionEvent.buttonLink,
        contactEnabled: actionEvent.contactEnabled ? actionEvent.contactEnabled : false,
        contactOwner: actionEvent.publicOwnerEmail,
        eventMedias: [],
        hasMore: true,
        fullGalleryEnabled: actionEvent.fullGalleryEnabled,
        fontColor: actionEvent.fontColor ? actionEvent.fontColor : '#000000',
        passwordProtected: actionEvent.passwordProtected,
        error: actionEvent.error,
        errorCode: actionEvent.errorCode,
        accentColor: actionEvent.micrositeAccentColor,
        background: hasFeaturedImageEnabled && actionEvent.backgroundUrl,
        organization: actionEvent.organization || { plan: 'Plus', licenseCount: 0 },
        liveGallery: actionEvent.liveGallery,
        analytics: actionEvent.analytics,
        analyticsToken: actionEvent.analyticsToken,
        audioEnabled:
          _.get(actionEvent, 'captureConfig.modeConfiguration.videoConfiguration.audioEnabled', false) ||
          actionEvent.eventType === '360', // Force audioEnabled for 360 events (SC-67833)
        virtualBoothConfig: actionEvent.virtualBoothConfig,
        captureConfig: _.get(actionEvent, 'captureConfig'),
        faceEffects: _.get(actionEvent, 'faceEffects'),
        fiestaEvent: _.get(actionEvent, 'fiestaEvent'),
      };

      // translate event based on branding settings
      if (data.liveGallery?.useBrandingSettings) {
        event = {
          ...event,
          accentColor: actionEvent.brandingSettings.accentColor,
          subtitle: actionEvent.brandingSettings.subtitle?.enabled ? actionEvent.brandingSettings.subtitle?.value : '',
          logo: (actionEvent.brandingSettings.logo?.enabled && actionEvent.brandingSettings?.logo?.imageUrl) || '',
          logoLink: (actionEvent.brandingSettings.logo?.enabled && actionEvent.brandingSettings?.logo?.linkUrl) || '',
          background:
            (actionEvent.brandingSettings.featuredImage?.enabled &&
              actionEvent.brandingSettings.featuredImage?.imageUrl) ||
            '',
          buttonFlag: actionEvent.brandingSettings.ctaSection?.enabled,
          buttonText: actionEvent.brandingSettings.ctaSection?.buttonLabel,
          buttonLink: actionEvent.brandingSettings.ctaSection?.buttonUrl,
          liveGallery: {
            ...event.liveGallery,
            theme: actionEvent.brandingSettings.featuredImage?.theme,
            companySection: actionEvent.brandingSettings.company?.enabled,
            company: actionEvent.brandingSettings.company,
            socialMediaSection: actionEvent.brandingSettings.social?.enabled,
            leadSection:
              actionEvent.brandingSettings.logo?.enabled ||
              actionEvent.brandingSettings.featuredImage?.enabled ||
              actionEvent.brandingSettings.subtitle?.enabled ||
              actionEvent.brandingSettings.ctaSection?.enabled,
          },
        };
      }

      // finishing touches
      // // set subtitle to show capture count if empty
      // if (event.subtitle === '' && totalPhotos && actionEvent.fullGalleryEnabled) {
      //   event.subtitle = `${totalPhotos} Captures`;
      // }
      // default accent color
      if (event.accentColor === '') {
        event.accentColor = '#9775f6';
      }
      // set accent contrast color (used for text color with the accent background)
      event.contrastColor = event.accentColor ? getContrastColor(event.accentColor) : '#ffffff';

      return event;
    }
    if (errors && errors[0] && errors[0].code) {
      const errorCode = errors[0].code;
      return {
        code: errorCode,
      };
    }

    return null;
  }

  transformAnalytics({ eventId, analytics, surveyRows }) {
    // Squash custom survey answers with only 1 vote
    surveyRows.forEach((surveyRow) => {
      const row = surveyRow;
      const { question, answers } = row;
      const uniqueAnswers = [];
      const finalAnswers = answers.reduce((result, current) => {
        const answer = current;
        answer.value = answer.value[0].toUpperCase() + answer.value.slice(1);
        if (answer.count > 1) {
          result.push(answer);
        } else {
          uniqueAnswers.push(answer);
        }
        return result;
      }, []);
      if (uniqueAnswers.length > 4) {
        row.answers = finalAnswers;
        row.uniqueAnswers = uniqueAnswers.map((a) => a.value);
      } else {
        row.answers = finalAnswers.concat(uniqueAnswers);
        row.uniqueAnswers = [];
      }
    });

    const analyticsTransformed = {
      eventId,
      analytics: {
        totalPhotos: analytics.internal.totalPhotos,
        totalShares: analytics.internal.totalShares,
        totalViews: analytics.liveGallery.totalViewCount,
        totalCtaClicks: analytics.liveGallery.totalCtaClicks,
        totalQROpened: analytics.liveGallery.totalQROpened,
        totalDownloads: analytics.liveGallery.totalShareDownload,
        photosLast30Minutes: analytics.internal.photosLast30Minutes,
        mediaViewsAndShares: analytics.liveGallery.mediaViewsAndShares,
        captureTypes: {
          photo: {
            name: 'Photo',
            value: analytics.internal.photosByCaptureMode.photo,
          },
          boomerang: {
            name: 'Boomerang',
            value: analytics.internal.photosByCaptureMode.boomerang,
          },
          gif: {
            name: 'GIF',
            value: analytics.internal.photosByCaptureMode.gif,
          },
          video: {
            name: 'Video',
            value: analytics.internal.photosByCaptureMode.video,
          },
        },
        shareTypes: {
          email: {
            name: 'E-mail',
            value: analytics.internal.sharesEmailed,
          },
          sms: {
            name: 'SMS',
            value: analytics.internal.sharesSmsed,
          },
        },
        liveGalleryShareTypes: {
          email: {
            name: 'E-mail',
            value: analytics.liveGallery.totalShareEmail,
          },
          twitter: {
            name: 'Twitter',
            value: analytics.liveGallery.totalShareTwitter,
          },
          facebook: {
            name: 'Facebook',
            value: analytics.liveGallery.totalShareFacebook,
          },
          // instagram: {
          //   name: 'Instagram',
          //   value: analytics.liveGallery.totalShareInstagram,
          // },
          // snapchat: {
          //   name: 'Snapchat',
          //   value: analytics.liveGallery.totalShareSnapchat,
          // },
          pinterest: {
            name: 'Pinterest',
            value: analytics.liveGallery.totalSharePinterest,
          },
          reddit: {
            name: 'Reddit',
            value: analytics.liveGallery.totalShareReddit,
          },
          tumblr: {
            name: 'Tumblr',
            value: analytics.liveGallery.totalShareTumblr,
          },
          /* download: {
                        name: 'Direct Download',
                        value: analytics.liveGallery.totalShareDownload
                    } */
        },
        liveGalleryViewTypes: {
          mobile: {
            name: 'Mobile',
            value: analytics.liveGallery.totalMobileViewPerc,
          },
          tablet: {
            name: 'Tablet',
            value: analytics.liveGallery.totalTabletViewPerc,
          },
          desktop: {
            name: 'Desktop',
            value: analytics.liveGallery.totalDesktopViewPerc,
          },
        },
        surveyResponses: analytics.internal.surveyResponses,
        surveyQuestions: surveyRows,
      },
    };

    return analyticsTransformed;
  }

  transformMedia({ mediaBody }) {
    const { data } = mediaBody;
    if (data) {
      return this.transformMedias({
        mediasBody: {
          data: {
            photosBefore: [data],
          },
        },
      });
    }
    return null;
  }

  formatMedias(photos) {
    const medias = {};
    photos.forEach((photo) => {
      medias[photo.mediaId] = {};
      medias[photo.mediaId].src = photo.videoThumbnailUrl || photo.thumbnailUrl;
      medias[photo.mediaId].static = photo.thumbnailUrl;
      medias[photo.mediaId].original = photo.mediaUrl;
      medias[photo.mediaId].id = photo.mediaId;
      medias[photo.mediaId].captureMode = photo.captureMode;
      // medias[photo.publicId].publicId = photo.publicId;
      if (photo.type === 'video') {
        medias[photo.mediaId].type = 'video';
      } else {
        medias[photo.mediaId].type = 'image';
      }
      medias[photo.mediaId].isTemplate = false;
    });
    return medias;
  }

  transformMedias({ mediasBody }) {
    const { data } = mediasBody;
    if (!data) {
      return null;
    }
    if (_.isArray(data)) {
      return this.formatMedias(data);
    }
    let medias = {};
    if (data.photosBefore) {
      const { photosBefore } = data;
      medias = this.formatMedias(photosBefore);
    }
    if (data.photosAfter) {
      const { photosAfter } = data;
      medias = { ...medias, ...this.formatMedias(photosAfter) };
    }
    return medias;
  }

  logIn({ user }) {
    const { email, password } = user;
    return this._salsaRestApi('operators/login?level=2', {
      method: 'POST',
      body: { email, password },
    });
  }

  signUp({ user }) {
    return this._salsaRestApi('operators/', {
      method: 'PUT',
      body: user,
    });
  }

  resetPasswordInitiate({ email }) {
    const query = `mutation {
            resetPasswordInitiate(email: "${email}") {
                email
                isEmailInvalid
            }
        }`;

    return this._fetchFromSalsa(query);
  }

  resetPasswordValidate({ token }) {
    const query = `query {
            resetPasswordValidate(token: "${token}") {
                token
                isTokenInvalid
                isTokenExpired
            }
        }`;

    return this._fetchFromSalsa(query);
  }

  resetPasswordFinally({ token, newPassword }) {
    const query = `mutation {
            resetPasswordFinally(token: "${token}", newPassword: "${newPassword}")
        }`;

    return this._fetchFromSalsa(query);
  }

  getPlans(env) {
    return this._salsaRestApi(`/plans?stripe-env=${env}`);
  }

  getDevices({ accessToken }) {
    return this._salsaRestApi('/devices/?level=5', { method: 'GET', headers: { 'x-access-token': accessToken } });
  }

  removeDevice({ accessToken, deviceId }) {
    return this._salsaRestApi('/devices/?level=5', {
      method: 'DELETE',
      body: { deviceId },
      headers: { 'x-access-token': accessToken },
    });
  }

  sharePhoto({ eventId, mediaId, email }) {
    const query = `mutation {
            share(photoId: "${mediaId}", emailAddresses: "${email}", micrositeShare: true) {
                destination
                error
            }
        }`;
    return this._fetchFromSalsa(query, null);
  }

  passwordToken({ id, password, idType }) {
    let query;
    if (idType === 'portfolio') {
      query = `query {
                portfolioLogin(id: "${id}", password: "${password}"){
                    token
                    hint
                    error
                    errorCode
                }
            }`;
    } else {
      query = `query {
                eventLogin(id: "${id}", password: "${password}"){
                    token
                    hint
                    error
                    errorCode
                }
            }`;
    }
    return this._fetchFromSalsa(query, null);
  }

  transformPasswordToken({ tokenBody }) {
    const { data, errors } = tokenBody;
    if (data && data.eventLogin) {
      return data.eventLogin;
    }
    if (data && data.portfolioLogin) {
      return data.portfolioLogin;
    }
    if (errors && errors[0]) {
      return {
        code: errors[0],
      };
    }
    return null;
  }

  checkSubdomain(eventId, subdomain) {
    const query = `query {
        checkSubdomain(eventId: "${eventId}") {
          valid
          subdomain
        }
      }
      `;
    return this._fetchFromSalsa(query, null, subdomain);
  }

  getAppEvent({ accessToken, eventId }) {
    return this._salsaRestApi(`/events/${eventId}?level=6`, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  createEvent({ accessToken, data }) {
    const payload = data;
    if (payload.id) {
      delete payload.id;
    }
    return this._salsaRestApi('/events', { method: 'PUT', body: payload, headers: { 'x-access-token': accessToken } });
  }

  updateEvent({ accessToken, data, id }) {
    return this._salsaRestApi(`/events/${id}?level=5`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  duplicateEvent({ accessToken, id }) {
    return this._salsaRestApi(`/events/${id}/duplicate`, {
      method: 'POST',
      headers: { 'x-access-token': accessToken },
    });
  }

  deleteEvents({ accessToken, data }) {
    return this._salsaRestApi('/events', { method: 'DELETE', body: data, headers: { 'x-access-token': accessToken } });
  }

  listEvents({ accessToken, level, data }) {
    return this._salsaRestApi(`/events?level=${level || 1}`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  listMedias({ accessToken, eventId, data }) {
    return this._salsaRestApi(`/events/${eventId}/medias`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  deleteMedias({ accessToken, eventId, data }) {
    return this._salsaRestApi(`/events/${eventId}/medias`, {
      method: 'DELETE',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  async uploadAsset({ suffix, file, category = 'asset', accessToken }) {
    const { data, type } = await readInputFile(file);
    const headers = {
      'Content-Type': type,
      'asset-category': category,
    };
    if (accessToken) {
      headers['x-access-token'] = accessToken;
    }
    if (suffix) {
      headers['x-filename'] = `overlay-${suffix}`;
    }
    const result = await this._salsaRestApi('/operators/assets/upload', { method: 'PUT', body: data, headers });
    return result;
  }

  listAssets({ accessToken, data }) {
    return this._salsaRestApi('/operators/assets', {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  loadSysAssets({ category }) {
    return this._salsaRestApi('/static/sysAssets', { method: 'GET' });
  }

  deleteAssets({ accessToken, data }) {
    return this._salsaRestApi('/operators/assets', {
      method: 'DELETE',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  emailLinks({ accessToken, eventId, data }) {
    return this._salsaRestApi(`/events/${eventId}/email/links`, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  emailCancellation({ accessToken, data }) {
    return this._salsaRestApi('/organizations/email/cancel', {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  addSendingDomain({ accessToken, domain }) {
    return this._salsaRestApi('/organizations/sending-domain', {
      method: 'PUT',
      body: { domain },
      headers: { 'x-access-token': accessToken },
    });
  }

  deleteSendingDomain({ accessToken }) {
    return this._salsaRestApi('/organizations/sending-domain', {
      method: 'DELETE',
      headers: { 'x-access-token': accessToken },
    });
  }

  updateOrganization({ accessToken, requestBody, organizationId }) {
    if (requestBody.pause) {
      return this._salsaRestApi('/organizations/pause', { method: 'POST', headers: { 'x-access-token': accessToken } });
    }
    return this._salsaRestApi(`/organizations/${organizationId}`, {
      method: 'POST',
      body: requestBody,
      headers: { 'x-access-token': accessToken },
    });
  }

  updateOperator({ accessToken, data }) {
    return this._salsaRestApi('/operators/me?level=6', {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  updateAcademyVideoProgress({ vimeoId, currentViewPercent, accessToken }) {
    return this._salsaRestApi('/operators/me/videoProgress', {
      method: 'POST',
      body: { vimeoId, currentViewPercent },
      headers: { 'x-access-token': accessToken },
    });
  }

  getPrintTemplate({ printTemplateId, accessToken }) {
    return this._salsaRestApi(`/operators/printTemplates/${printTemplateId}?level=3`, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  createPrintTemplate({ eventLongId, data, accessToken }) {
    let url = '/operators/printTemplates';
    if (eventLongId) {
      url += `/${eventLongId}`;
    }
    return this._salsaRestApi(url, {
      method: 'PUT',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  updatePrintTemplate({ printTemplateId, data, accessToken }) {
    const url = `/operators/printTemplates/${printTemplateId}`;
    return this._salsaRestApi(url, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  listPrintTemplates({ data, accessToken }) {
    const url = '/operators/printTemplates?level=6';
    return this._salsaRestApi(url, {
      method: 'POST',
      body: data,
      headers: { 'x-access-token': accessToken },
    });
  }

  deletePrintTemplates({ ids, accessToken }) {
    const url = '/operators/printTemplates';
    return this._salsaRestApi(url, {
      method: 'DELETE',
      body: { ids },
      headers: { 'x-access-token': accessToken },
    });
  }

  listSysTemplates({ templateType, accessToken }) {
    const url = '/operators/sysTemplates?level=6';
    return this._salsaRestApi(url, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  downloadSysTemplate({ key, accessToken }) {
    const url = '/operators/sysTemplateDownload';
    return this._salsaRestApi(url, {
      method: 'POST',
      body: { key },
      headers: { 'x-access-token': accessToken },
    });
  }

  listSysFonts({ accessToken }) {
    const url = '/operators/sysFonts?level=6';
    return this._salsaRestApi(url, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  listPrintTemplateLayouts({ accessToken }) {
    const url = '/operators/printTemplates/layouts';
    return this._salsaRestApi(url, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  listPoseTips() {
    const url = '/static/poseTips/default';
    return this._salsaRestApi(url, {
      method: 'GET',
    });
  }

  getAppUserStats({ accessToken }) {
    return this._salsaRestApi('/organizations/stats', { method: 'GET', headers: { 'x-access-token': accessToken } });
  }

  listPortfolioEvents({ data, level, id }) {
    return this._salsaRestApi(`/operators/${id}/events?level=${level || 1}`, {
      method: 'POST',
      body: data,
    });
  }

  getPortfolioSettings({ id, passwordAccessToken }) {
    return this._salsaRestApi(`/operators/${id}?level=2`, {
      method: 'POST',
      body: { passwordAccessToken },
    });
  }

  getAppUserOrganization({ organizationId, accessToken }) {
    return this._salsaRestApi(`/organizations/${organizationId}?level=5`, {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  getAppUserOperator({ accessToken }) {
    return this._salsaRestApi('/operators/me?level=6', { method: 'GET', headers: { 'x-access-token': accessToken } });
  }

  getAppUserPricing({ accessToken, plan }) {
    return this._salsaRestApi('/organizations/pricing?level=5', {
      method: 'POST',
      body: plan,
      headers: { 'x-access-token': accessToken },
    });
  }

  updateUserPlan({ accessToken, plan }) {
    return this._salsaRestApi('/organizations/plan', {
      method: 'POST',
      body: plan,
      headers: { 'x-access-token': accessToken },
    });
  }

  subscribe({ accessToken, plan }) {
    return this._salsaRestApi('/organizations/subscribe', {
      method: 'POST',
      body: plan,
      headers: { 'x-access-token': accessToken },
    });
  }

  cancelPlanSchedule({ accessToken }) {
    return this._salsaRestApi('/organizations/schedule', {
      method: 'DELETE',
      headers: { 'x-access-token': accessToken },
    });
  }

  verifyEmail({ token }) {
    return this._salsaRestApi('/operators/verifyEmail', {
      method: 'POST',
      body: { token },
    });
  }

  resendVerification(email) {
    return this._salsaRestApi('/operators/sendVerifyEmail', {
      method: 'POST',
      body: { email },
    });
  }

  async uploadMedia({ source, eventId, captureMode, frameRate }) {
    let data;
    let type;
    if (_.isString(source)) {
      data = { url: source, frameRate };
      type = 'application/json';
    } else {
      const file = await readInputFile(source);
      data = file.data;
      // type = file.type;
      // data = await source.arrayBuffer();
      type = source.type;
    }
    const headers = {
      'Content-Type': type,
      'x-capture-mode': captureMode,
    };
    const result = await this._salsaRestApi(`/events/${eventId}/medias/upload/public`, {
      method: 'PUT',
      body: data,
      headers,
    });
    return result;
  }

  async processMedia({ source, eventId, settings }) {
    let data;
    let type = 'application/json';
    if (_.isString(source)) {
      data = { url: source };
    } else {
      const file = await readInputFile(source);
      data = file.data;
      // data = await source.arrayBuffer();
      type = source.type;
    }

    const headers = { 'Content-Type': type };
    try {
      headers['x-settings'] = JSON.stringify(settings);
    } catch (e) {
      // TODO: Do something with this error
    }
    const result = await this._salsaRestApi(`/events/${eventId}/medias/process/public`, {
      method: 'PUT',
      body: data,
      headers,
    });

    /* const options = {
            method: 'PUT',
            url: this.URL.replace(/\/gql$/, '') + `/events/${eventId}/medias/process/public`,
            data, headers
        };
        const res = await axios(options, { timeout: 300e3 }).then(r => r.data); */
    // console.log(res)
    // return res;

    if (!result.ok) {
      const { error } = await result.json();
      throw new Error(error);
    }
    if (/^text/i.test(result.headers.get('Content-Type'))) {
      const txt = (await result.text()).split('|');
      const err = txt.filter((line) => /^error/.test(line)).map((line) => JSON.parse(line.split('error:')[1]))[0];
      const data = txt.filter((line) => /^data/.test(line)).map((line) => JSON.parse(line.split('data:')[1]))[0];
      // console.log('result:', err, data);
      if (err) {
        throw new Error(err);
      }
      if (!data) {
        throw new Error('No data');
      }
      return data;
    }
    /* if (/application\/json/i.test(result.headers.get('Content-Type'))) {
            return result.json();
        } */

    const b = await result.blob();
    const url = URL.createObjectURL(b);
    return { url };
  }

  updateEnvironment({ env }) {
    let url;
    if (env) {
      url = require('../constants').get(env).salsaURL;
    } else {
      url = require('../constants').get().salsaURL;
    }
    this.URL = url;
  }

  checkForMedia({ sourceId }) {
    return this._salsaRestApi(`/qr/${sourceId}`, {
      method: 'GET',
    });
  }

  submitPendingShare({ sourceId, target, shareType }) {
    return this._salsaRestApi('/qr', {
      method: 'PUT',
      body: {
        sourceId,
        type: shareType,
        target,
      },
    });
  }

  getVirtualBooth({ eventId }) {
    return this._salsaRestApi(`/events/${eventId}/virtualBooth?level=5`, {
      method: 'GET',
    });
  }

  getS3FormData({ eventId, contentType, accessToken }) {
    return this._salsaRestApi(`/events/${eventId}/medias/upload_data`, {
      method: 'POST',
      body: { contentType },
      headers: { 'x-access-token': accessToken },
    });
  }

  saveCapture({ eventId, filename, captureMode, captureTime, accessToken, uploadSource }) {
    return this._salsaRestApi(`/events/${eventId}/medias/upload_save`, {
      method: 'POST',
      body: { filename, captureMode, uploadSource },
      headers: { 'x-access-token': accessToken },
    });
  }

  getShopifyCustomerEmail({ queryStr, accessToken }) {
    return this._salsaRestApi('/operators/shopifyCustomer', {
      method: 'POST',
      body: { query_str: queryStr },
      headers: { 'x-access-token': accessToken },
    });
  }

  getMarketingMaterials({ accessToken }) {
    return this._salsaRestApi('/operators/marketingMaterials', {
      method: 'GET',
      headers: { 'x-access-token': accessToken },
    });
  }

  downloadMarketingMaterial({ key, accessToken }) {
    return this._salsaRestApi('/operators/marketingMaterialDownload', {
      method: 'POST',
      body: { key },
      headers: { 'x-access-token': accessToken },
    });
  }

  getProposalData({ eventId }) {
    return this._salsaRestApi(`/events/${eventId}/proposal?level=6`, {
      method: 'GET',
    });
  }

  listCountries() {
    return this._salsaRestApi('/countries', {
      method: 'GET',
    });
  }

  _fetchFromSalsa(query, accessToken, subdomain) {
    const headers = {
      'Content-Type': 'application/json',
      'x-app-version': 'web',
      'x-sse-source': sseSource,
    };

    if (accessToken) {
      headers['x-access-token'] = accessToken;
    }
    if (
      subdomain &&
      !['dev', 'beta', 'staging', 'www', 'boothpics', 'localboothpics'].includes(subdomain) &&
      !subdomain.startsWith('localhost')
    ) {
      headers['X-Subdomain'] = subdomain;
    }

    return fetch(`${this.URL}`, {
      method: 'POST',
      body: JSON.stringify({ query }),
      headers,
    });
  }

  _salsaRestApi(path, options) {
    const isProd = publicRuntimeConfig.DOMAIN === 'prod';
    const params = _.defaultsDeep(options, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-app-version': 'web',
        'x-sse-source': sseSource,
      },
    });
    if (typeof params.body === 'object' && params.headers['Content-Type'] === 'application/json') {
      params.body = JSON.stringify(params.body);
    }
    const id = uniqid();
    const t = Date.now();
    if (!isProd) {
      const logOptions = _.clone(params);
      if (logOptions.body && !_.isString(logOptions.body)) {
        logOptions.body = typeof logOptions.body;
      }
    }
    return fetch(`${this.URL.replace(/\/gql$/, '')}/${path.replace(/^\/+/, '')}`, params).then(async (res) => {
      // Handle unauthorized (401) errors
      if (res.status === 401) {
        const clone = res.clone();
        const data = await clone.json();
        if (!data.errors) {
          return res;
        }
        // Handle unauthorized user trying to access non-production environment
        if (!isProd && data.errors[0].message.includes('Invalid billing environment')) {
          // Redirect to prod web app
          window.location.href = `${prodWebAppURL}/admin/events`;
          return;
        }
        // Handle invalid access token
        if (data.errors.find((err) => err.message === 'Invalid access token.')) {
          // Remove invalid cookie and redirect to login page
          Cookies.remove('operator');
          window.location.href = `${webAppURL}/login`;
          return;
        }
      }
      return res;
    });
  }
}

export default BackendSalsa;
