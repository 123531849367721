import _ from 'lodash';
import Cookies from 'js-cookie';

const { publicRuntimeConfig } = require('next/config').default();
const { fiestaURL, sseSource, webAppURL } = require('../constants').get();
const { webAppURL: prodWebAppURL } = require('../constants').get('prod');

class BackendFiesta {
  constructor() {
    this.URL = fiestaURL;
  }

  getProposalData({ eventId }) {
    return this._fiestaRestApi(`/events/${eventId}/proposal?level=6`, {
      method: 'GET',
    });
  }

  getProposalOverlays({ eventId }) {
    return this._fiestaRestApi(`/events/${eventId}/proposalOverlays?level=6`, {
      method: 'GET',
    });
  }

  getThreeSixtyPreview(payload) {
    return this._fiestaRestApi('/operators/threeSixtyProposalPreview', {
      method: 'POST',
      body: payload,
    });
  }

  getPortfolioSettings({ id, passwordAccessToken }) {
    return this._fiestaRestApi(`/operators/${id}?level=2`, {
      method: 'POST',
      body: { passwordAccessToken },
    });
  }

  listPortfolioEvents({ data, level, id }) {
    return this._fiestaRestApi(`/operators/${id}/events?level=${level || 1}`, {
      method: 'POST',
      body: data,
    });
  }

  async _fiestaRestApi(path, options) {
    const isProd = publicRuntimeConfig.DOMAIN === 'prod';
    const params = _.defaultsDeep(options, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-app-version': 'web',
        'x-sse-source': sseSource,
      },
    });

    if (typeof params.body === 'object' && params.headers['Content-Type'] === 'application/json') {
      params.body = JSON.stringify(params.body);
    }

    const url = `${this.URL.replace(/\/gql$/, '')}/${path.replace(/^\/+/, '')}`;
    const res = await fetch(url, params);

    // Handle unauthorized (401) errors
    if (res.status === 401) {
      const clone = res.clone();
      const data = await clone.json();
      if (!data.errors) {
        return res;
      }
      // Handle unauthorized user trying to access non-production environment
      if (!isProd && data.errors[0].message.includes('Invalid billing environment')) {
        // Redirect to prod web app
        window.location.href = `${prodWebAppURL}/admin/events`;
        return;
      }
      // Handle invalid access token
      if (data.errors.find((err) => err.message === 'Invalid access token.')) {
        // Remove invalid cookie and redirect to login page
        Cookies.remove('operator');
        window.location.href = `${webAppURL}/login`;
        return;
      }
    }

    return res;
  }
}

export default BackendFiesta;
